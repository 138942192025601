<template>
  <div>
    <order-status :is-show-all-status="true"/>
    <ag-table
      :grid-options="gridOptions"
      :row-data="rowData"
      :rtl="false"
      @emitShowCurrentRecords="fetchAllOrders('orphan_gift_orders')"
      @emitShowCompletedRecords="fetchAllOrders('orphan_gift_orders',true)"
    />

    <vs-popup
      title="تفاصيل الطلب"
      :active.sync="popupActiveDetails"
      :button-close-hidden="true"
    >
      <div class="flex justify-end items-center mt-2 mb-6 px-2">
        <vs-button
          class="flex items-center"
          @click="rowClicked(order.id)"
        >
          <div class="flex items-center justify-between mb-0">
            <feather-icon
              class="mr-4 iconColor"
              icon="ListIcon"
              svg-classes="h-6 w-6"
            />
            <span>عرض محتوى الطلب</span>
          </div>
        </vs-button>
      </div>


      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-6 px-4">
        <div>
          <label>المعرّف :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.id"
          />
        </div>

        <div>
          <label>اسم الزبون :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.user.full_name"
          />
        </div>

        <div>
          <label>رقم الزبون :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.user.mobile"
          />
        </div>

        <div v-if="order.user_address">
          <label>عنوان الزبون :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.user_address.address"
          />
        </div>
        <div v-if="order.user_address">
          <label>شارع :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.user_address.street"
          />
        </div>
        <div v-if="order.user_address">
          <label>بجانب :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.user_address.beside"
          />
        </div>
        <div>
          <label>نوع الطلب :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.order_type"
          />
        </div>


        <div>
          <label>التكلفة الإجمالية :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.total_cost"
          />
        </div>


        <div>
          <label>الوقت المتوقع للتوصيل :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.expected_delivery_date"
          />
        </div>
      </div>


      <div class="mt-6 grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6 px-4">
        <div v-if="order.rejection_reason">
          <label>سبب الرفض :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.rejection_reason"
          />
        </div>
        <div
          v-if="order.message"
          class="col-span-2"
        >
          <label>الملاحظات المرسلة للزبون :</label>
          <vs-input
            placeholder=""
            name="title"
            disabled
            color="#5E445A"
            class="w-full mt-3"
            v-model="order.message"
          />
        </div>
      </div>
      <div class="mt-8 px-4 w-full md:w-1/2 lg:w-1/3">
        <label>حالة الطلب :</label>
        <p
          class="w-full mt-3"
          :class="[this.order.status == 'Rejected' ? 'rejected':'' , this.order.status == 'Approved' ? 'approved':'' , this.order.status == 'Ready' ? 'ready':'' , this.order.status == 'UnderDelivery' ? 'underDelivery':'' , this.order.status == 'Delivered' ? 'delivered':'' , this.order.status == 'Review' ? 'underReview':'']"
        >
          {{ order.arabic_status }}
        </p>
      </div>


      <div
        v-if="order.user_note"
        class="mt-6 px-4"
      >
        <label>ملاحظات الزبون :</label>
        <vs-textarea
          placeholder="الملاحظات"
          v-model="order.user_note"
          disabled
          class="w-full mt-3"
          color="#5E445A"
        />
      </div>


      <div class="flex justify-center items-center mt-8 px-4">
        <vs-button
          @click="popupActiveDetails=false"
          type="filled"
          ycolor="success"
        >
          حسناً
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      :title="this.statusAction"
      :active.sync="popupActive"
      :button-close-hidden="true"
    >
      <vs-progress
        v-if="isLoading"
        indeterminate
        color="primary"
      />
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
      >
        <form>
          <div class="vx-row mt-5 justify-center">
            <h3> سيتم تغيير حالة الطلب من</h3>
            <h3
              :style="{'color':fromStatusColor }"
              class="vx-col"
            >
              {{ fromStatus }}
            </h3>
            <h3> إلى </h3>
            <h3
              :style="{'color':toStatusColor }"
              class="vx-col"
            >
              {{ toStatus }}
            </h3>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col w-full mb-2">
              <ValidationProvider
                name="الرسالة"
                :rules="`${(newStatus === 'Rejected') ? 'required' :''}`"
                v-slot="{errors}"
              >
                <label>الرسالة</label>
                <vs-input
                  placeholder="الرسالة"
                  v-model="message"
                  class="w-full"
                />
                <span class="text-danger">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="vx-row mt-5">
            <div class="vx-col sm:w-1/2 w-full mb-2">
              <vs-button
                :disabled="isLoading"
                ycolor="success"
                type="filled"
                @click.prevent="handleSubmit(onSubmit)"
              >
                إرسال
              </vs-button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vs-popup>
  </div>
</template>

<script>
import AgTable from '../../../shared/shared-components/ag-grid/AgTable';
import AgTableBtnCell from "@/app/shared/shared-components/ag-grid/AgTableBtnCell";
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import AgIcon from "@/app/ecommerce/ecommerce-components/AgIcon";
import AgTableDangerIcon from "@/app/ecommerce/ecommerce-components/AgTableDangerIcon";
import utilities from "@/app/shared/utilities";
import OrderStatus from '../../ecommerce-components/OrderStatus';
import AgTableIsActiveIcon from "@/app/shared/shared-components/ag-grid/AgTableIsActiveIcon";

const orderRepo = RepositoryFactory.get('order');

export default {
  name: "PersonalOrder",
  components: {
    AgTable,
    OrderStatus
  },
  data() {
    return {
      isLoading: true,
      gridOptions: null,
      rowData: [],
      popupActiveDetails: false,
      popupActive: false,
      imageRequired: true,
      imagePreview: null,
      dialogTitle: String,
      statusAction: '',
      newStatus: '',
      fromStatus: '',
      fromStatusColor: '',
      toStatus: '',
      toStatusColor: '',
      orderStatusAction: '',
      order: {
        id: null,
        status: '',
        arabic_status: '',
        is_late: '',
        user_note: '',
        orderable: '',
        message: '',
        rejection_reason: '',
        order_type: '',
        expected_delivery_date: '',
        user: {},
        user_address: {},
        donation_order: {},
        friend_gift: {}
      },
      orderId: '',
      message: '',
      type: '',
    };
  },
  methods: {
    createColDefs() {
      let self = this;
      return [
        {
          headerName: 'المعرف',
          field: 'id',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          imagePreview: null,
          headerCheckboxSelection: true,
          checkboxSelection: true,

          // valueGetter: (params) => {
          //     return params.data.id + ' ' + params.data.is_late;
          // },

          cellRendererFramework: AgTableDangerIcon,
          cellRendererParams(params) {
            return {
              myid: params.data.id,
              is_late: params.data.is_late,
              mystatus: params.data.status,
            };
          },

        },
        {
          headerName: 'اسم الزبون',
          field: 'user.full_name',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'رقم الزبون',
          field: 'user.mobile',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'عنوان الزبون',
          field: 'user_address',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          width: 260,
          valueGetter: addressGetter
        },
        {
          headerName: 'توصيل الطلب',
          field: 'with_delivery',
          cellRendererFramework: AgTableIsActiveIcon,
          cellRendererParams: {
            isActiveIcon: 'CheckCircleIcon',
            notActiveIcon: 'XCircleIcon'
          }
        },
        {
          headerName: 'الوقت المتوقع للتوصيل',
          field: 'expected_delivery_date',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
          minWidth: 250,
        },
        {
          headerName: 'التكلفة الإجمالية',
          field: 'total_cost',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'تكلفة الطلب',
          field: 'sub_products_price',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'تكلفة التوصيل',
          field: 'delivery_price',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'تكلفة التغليف',
          field: 'package_price',
          filter: 'agTextColumnFilter',
          suppressSizeToFit: true,
        },
        {
          headerName: 'خيارات الطلب',
          valueGetter:
            function getObject(params) {
              return params;
            },
          cellRendererFramework: AgIcon,
          cellRendererParams: {
            click(valueGetter) {
              self.changeOrderStatus(valueGetter);
            },
            clickToReject(valueGetter) {
              self.changeOrderStatus(valueGetter, true);
            },
          },
          suppressSizeToFit: true,
        },

        {
          headerName: 'الإجراءات',
          field: 'id',
          cellRendererFramework: AgTableBtnCell,
          cellRendererParams: {
            viewRecordDetails: function (record) {
              self.viewRecordDetails(record);
            },
            actions: ['view']
          },
          suppressSizeToFit: true,
        },
      ];
    },
    rowClicked(id) {
      this.popupActiveDetails = false;
      setTimeout(function () {
        this.$router.push(`/ecommerce/orders/${id}/order_details/personal`);
      }.bind(this), 200);
    },
    changeCellColor(params) {
      return {'color': utilities.setOrderStatusColor(params.node.data.status)};
    },
    changeOrderStatus(record, isRejected = false) {
      this.orderId = record.data.id;
      let statusObject = utilities.changeOrderStatus(record.data, isRejected);
      this.statusAction = statusObject.statusAction;
      this.newStatus = statusObject.newStatus;
      this.fromStatus = statusObject.fromStatus;
      this.toStatus = statusObject.toStatus;
      this.fromStatusColor = statusObject.fromStatusColor;
      this.toStatusColor = statusObject.toStatusColor;
      if (this.newStatus != null) {
        this.openPopup();
      }
    },
    openPopup() {
      this.$refs.validationObserver.reset();
      this.popupActive = true;
    },
    onSubmit() {
      this.isLoading = true;
      orderRepo.changeOrderStatus(this.orderId, this.newStatus, this.message).then((response) => {
        let index = this.rowData.map((m) => {
          return m.id;
        }).indexOf(response.data.data.id);
        if (response.data.data.status === 'Delivered' || response.data.data.status === 'Rejected')
          this.rowData.splice(index, 1);
        else
          this.rowData[index] = response.data.data;
        this.rerender();
        this.popupActive = false;
        this.isLoading = false;
      });
    },
    viewRecordDetails(record) {
      this.popupActiveDetails = true;
      this.order = record;
    },
    // used to rerender updated row
    rerender() {
      this.gridApi.setRowData(this.rowData);
    },
    fetchAllOrders(ordersType, isCompleted = false) {
      this.type = ordersType;
      orderRepo.fetchAllOrders(ordersType, isCompleted).then((data) => {
        this.rowData = data;
        this.isLoading = false;
      });
    },
  },
  created() {
    // prepare ag grid options
    this.gridOptions = {};
    this.gridOptions.columnDefs = this.createColDefs();
    this.gridOptions.defaultColDef = {resizable: false,};
    this.fetchAllOrders('orphan_gift_orders');

  },
  mounted() {
    this.gridApi = this.gridOptions.api;
  },
};
window.addressGetter = function (params) {
  if (params.data.user_address)
    return `${params.data.user_address.address} -شارع ${params.data.user_address.street} -جانب ${params.data.user_address.beside}`;
};
</script>


<style scoped>


.iconColor {
  color: #ffffff;
}

.btnColor {
  background-color: #4B364E;
  color: white;
  border-radius: 5px;
}

.underReview {
  background-color: #FE948E;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}

.approved {
  background-color: #5F7A99;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}

.ready {
  background-color: #8158A4;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}

.underDelivery {
  background-color: #6495ED;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}

.delivered {
  background-color: #9ECC3B;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}

.rejected {
  background-color: #EF4141;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  padding: 0.7rem;
}


</style>
